<template>
  <div>
    <v-autocomplete
        v-model="newValue"
        :items="options" 
        :loading="loading"
        :search-input.sync="searchInput"
        background-color="white"
        @update:search-input="updateSearchInput"
        @change="emitValue"   
        :hide-no-data="!searchInput || searchInput.length < 3 || loading || hideNoData"
        :item-text="itemText"
        :item-value="itemValue"
        placeholder="Type to Search"
        :return-object="returnObject"
        outlined
        :clearable="clearable"
        :error="errors && errors.length > 0"
        :no-filter="noFilter"
        :class="errors && errors.length ? 'hasError' : ''"
        hide-details
        :disabled="disabled || false"
        :no-data-text="noDataText"
        allow-overflow
        :auto-select-first="autoSelectFirst"
        :label="required ? label + '*' : label"
        >
        
        <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
        <template v-slot:item="data">
            <slot name="item" v-bind="data" v-if="data && data.item">{{data.item[itemText]}}</slot>
        </template>
        
    </v-autocomplete>
    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
</template>

<script>

export default {
  name: 'Autocomplete',
  data() {
    return {
        newValue: null,
        searchInput: null,
    }
},
  props: {
        value: [String, Number, Boolean, Object],
        rules: String,
        options: {
            type: Array,
            default: () => []
        },
        label: String,
        clearable: Boolean,
        required: Boolean,
        errors: Array,
        disabled: Boolean,
        loading: {
            type: Boolean,
            default: false
        },
        noFilter: {
            type: Boolean,
            default: false
        },
        hideNoData: {
            type: Boolean,
            default: true
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        noDataText: {
            type: String,
            default: "No Results Found"
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: String,
            default: "value"
        },
        autoSelectFirst: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change',
        'searchinput'
    ],
    methods: {
        emitValue(v) {
            this.$emit('change', this.newValue)       
        },
        emitInput(v) {
            this.$emit('searchinput', v)   
        },
        updateSearchInput(v) {
            this.emitInput(v);
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.newValue = newVal;
                if (newVal !== null && newVal !== undefined) {
                    const objIndex = this.options.findIndex((o => o.id == newVal.id));
                    if (objIndex > -1) {
                      this.options[objIndex] = this.newValue;
                    } else {
                      this.options.push(this.newValue);
                    }
                }
                
            }
        }
    },
}
</script>
