<template>
  <Autocomplete 
    v-bind="{
      ...$props,
      options,
      value: newValue,
      loading,
      noFilter: true,
      returnObject: true,
      hideNoData: false,
      clearable: true,
      itemText: 'SeekDisplay',
      itemValue: 'MortgageeID'
    }"
    @change="updateValue"
    @searchinput="seekInput"
  >
    <!-- <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{data.item.MortgageeName}}</v-list-item-title>
        <v-list-item-subtitle>{{data.item.MortgageeAddress1}}, {{data.item.MortgageeCity}}, {{data.item.MortgageeState}} {{data.item.MortgageeZip}}</v-list-item-subtitle>
      </v-list-item-content>
    </template> -->
  </Autocomplete>
  
</template>

<script>
import Autocomplete from '@/components/form/Autocomplete.vue'
import { MortgageeService } from '@/api/MortgageeService'
import { sanitizeSeek } from '@/scripts/helper'

export default {
  name: "MortgageeAutocomplete",
  components: {
    Autocomplete
  },
  data() {
    return {
      newValue: null,
      options: [],
      loading: false,
      timeout: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String,
    MortgageeOrgID: {
      type: Number,
      default: null
    }
  },
  emits: [
    'change'
  ],
  methods: {
      updateValue(v) {
        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }

        this.emitValue();
      },
      emitValue() {
          this.$emit('change', this.newValue)       
      },
      seekInput(q) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.seek(q);
        }, 300)
      },
      seek(q) {
        q = sanitizeSeek(q);
        if (this.newValue && q == this.newValue.SeekDisplay && this.options.length > 0) return;
        if (!this.MortgageeOrgID) return;

        if (q && q.length > 2) {
          this.loading = true;
          
          MortgageeService.seek({
            q: q,
            mortgageeOrgId: this.MortgageeOrgID
          })
          .then((data) => {
            this.options = data;
          }).catch(() => {
            
          }).finally(() => {
            this.loading = false;
          });
            
        }
      },
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if ((!this.newValue) || (newVal && newVal.ID && this.newValue.ID != newVal.ID)) {
              this.newValue = newVal;
              //this.options.push(this.newValue);
            }

            if (newVal === null && this.newValue) {
              this.newValue = null;
              this.options = [];
              this.$forceUpdate();
            }
          }
      }
  },
}
</script>
